import React, { useState, useEffect } from "react";
import { ReactBingmaps } from "react-bingmaps";
import api from "../../../../core/services/api";

//custom components
import Main from "../../components/Main";
import { MapContainer, Flex } from "./styles";
import SidebarContainer from "./Sidebar";
import CustomSelect from "../../components/CustomSelect";

//trucks svg
import truck_gray from "../../assets/truck-gray.svg";
import truck_red from "../../assets/truck-red.svg";
import truck_green from "../../assets/truck-green.svg";
import truck_blue from "../../assets/truck-blue.svg";
import truck_orange from "../../assets/truck-orange.svg";
import truck_yellow from "../../assets/truck-yellow.svg";

//LOGOS JEEP
import logo_jeep from "../../assets/img/jeep.png";
import logo_brose from "../../assets/img/brose.png";
import logo_denso from "../../assets/img/denso.png";
import logo_pcma from "../../assets/img/PCMA.png";
import logo_lear from "../../assets/img/lear.png";
import logo_pirelli from "../../assets/img/pirelli.png";
import logo_magnet from "../../assets/img/magnet.png";
import logo_tiberina from "../../assets/img/tiberina.png";
import logo_pmc from "../../assets/img/PMC.png";
import logo_dhl from "../../assets/img/dhl.png";
import logo_faurecia from "../../assets/img/faurecia.png";
import logo_saintgoban from "../../assets/img/saint-goban.png";
import logo_revestcoat from "../../assets/img/revest-coat.png";
import logo_adler from "../../assets/img/adler.png";

//material
import LensIcon from "@material-ui/icons/Lens";

function MapaOperacional() {
  const usuario = JSON.parse(localStorage.getItem("usuario"));
  const [opensidebar, setOpensidebar] = useState(false);
  const [item_sidebar, setItemsidebar] = useState({});
  const [state, setState] = useState({
    counters: [
      { counter: "On Time" },
      { counter: "Atrasado" },
      { counter: "Atrasado / DHL" },
      { counter: "Em Ocorrência" },
      { counter: "Aguardando/Sem Pedido" },
      { counter: "Exceção" }
    ],
    logoPins: [
      {
        location: [-7.616889626225389, -34.96501922607422],
        option: { icon: logo_jeep, width: 5, height: 5 }
      },
      {
        location: [-7.617229919539509, -34.96030926704407],
        option: { icon: logo_jeep, width: 5, height: 5 }
      },
      {
        location: [-7.620739178573304, -34.96205806732178],
        option: { icon: logo_jeep, width: 5, height: 5 }
      },
      {
        location: [-7.6209, -34.9548],
        option: { icon: logo_jeep, width: 5, height: 5 }
      },
      {
        location: [-7.6128, -34.9602],
        option: { icon: logo_jeep, width: 5, height: 5 }
      },

      {
        location: [-7.618293334403993, -34.954386949539185],
        option: { icon: logo_brose, width: 5, height: 5 }
      },
      {
        location: [-7.621244296824545, -34.952595233917236],
        option: { icon: logo_dhl, width: 5, height: 5 }
      },
      {
        location: [-7.618559187707646, -34.956092834472656],
        option: { icon: logo_pcma, width: 5, height: 5 }
      },
      {
        location: [-7.619484355918119, -34.95125412940979],
        option: { icon: logo_lear, width: 5, height: 5 }
      },
      {
        location: [-7.6206541059671, -34.950696229934685],
        option: { icon: logo_pirelli, width: 5, height: 5 }
      },
      {
        location: [-7.61433741775523, -34.955148696899414],
        option: { icon: logo_magnet, width: 5, height: 5 }
      },
      {
        location: [-7.614815958001203, -34.95756268501282],
        option: { icon: logo_denso, width: 5, height: 5 }
      },
      {
        location: [-7.613880145465425, -34.952380657196045],
        option: { icon: logo_pmc, width: 5, height: 5 }
      },
      {
        location: [-7.616613137708655, -34.95555639266968],
        option: { icon: logo_faurecia, width: 5, height: 5 }
      },
      {
        location: [-7.616209038786336, -34.951876401901245],
        option: { icon: logo_saintgoban, width: 5, height: 5 }
      },
      {
        location: [-7.6117426568971105, -34.95232701301575],
        option: { icon: logo_magnet, width: 5, height: 5 }
      },
      {
        location: [-7.6110726955999795, -34.95394706726074],
        option: { icon: logo_tiberina, width: 5, height: 5 }
      },
      {
        location: [-7.6123, -34.956],
        option: { icon: logo_revestcoat, width: 5, height: 5 }
      },
      {
        location: [-7.6128, -34.9579],
        option: { icon: logo_adler, width: 5, height: 5 }
      }
    ],

    pushPins: [],
    filter: 0,
    ocurrences: [],
    local_users: usuario.person.resource.local_id
  });
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    getData();
    getOccurrences();
    const interval = setInterval(() => {
      getData();
      console.log("Intervalo 30s");
    }, 30000);
    return () => clearInterval(interval);
  }, [state.filter]);

  async function getOccurrences() {
    try {
      const data = await api.get(`occurrence`);
      setState(prev => ({
        ...prev,
        occurrences: data.data || []
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getData() {
    try {
      await sleep(5000);
      const data2 = await api.get(`route-tracking`);
      //FILTRANDO ROTAS
      const data3 = await api.get(`routes`);
      let routes = [];
      data3.data.forEach((item, index) => {
        let route = null;
        item.tasks.forEach((item2, index2) => {
          if (state.hasOwnProperty("local_user")) {
            if (state.local_users.includes(item2.local)) {
              route = item.name;
            }
          }
        });
        if (route) routes.push(route);
      });

      let pushPins = [];
      const it_sidebar = JSON.parse(localStorage.getItem("item_sidebar"));
      if (it_sidebar) {
        //console.log('Atualizar esse itemsidebar: ', it_sidebar)
        let aux = data2.data.filter(item => {
          return it_sidebar.route == item.route;
        });
        if (aux[0]) setItemsidebar(aux[0]);
      }
      data2.data.map(item => {
        pushPins.push({
          location: [parseFloat(item.lat), parseFloat(item.lon)],
          option: {
            icon: checkStatus(item.status),
            title: item.route + " / " + item.description
          },
          addHandler: { type: "click", callback: () => handleSidebar(item) },
          obj: item
        });
      });
      let filteredPushPins = pushPins;

      if (state.hasOwnProperty("local_user")) {
        if (!state.local_users.includes(60)) {
          //FILTRAR ROTAS USUARIOS
          filteredPushPins = pushPins.filter(item => {
            return routes.includes(item.obj.route);
          });
        }
      }

      if (state.filter != 0) {
        filteredPushPins = pushPins.filter(item => {
          return item.obj.status == state.filter;
        });
      }

      //inserindo logos no mapa
      state.logoPins.forEach(item => {
        filteredPushPins.push(item);
      });

      setState(prev => ({
        ...prev,
        telemetries: data2.data || [],
        pushPins: filteredPushPins
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  function checkStatus(status) {
    if (status === "red") {
      return truck_red;
    } else if (status === "green") {
      return truck_green;
    } else if (status === "blue") {
      return truck_blue;
    } else if (status === "orange") {
      return truck_orange;
    } else if (status === "yellow") {
      return truck_yellow;
    } else {
      return truck_gray;
    }
  }
  function handleSidebar(item) {
    setOpensidebar(false);
    setItemsidebar(item);
    setOpensidebar(true);
    localStorage.setItem("item_sidebar", JSON.stringify(item));
  }

  function selectColor(counter) {
    if (counter === "On Time") {
      return "#54d959";
    }
    if (counter === "Atrasado") {
      return "#f43f3c";
    }
    if (counter === "Em Ocorrência") {
      return "#FE8545";
    }
    if (counter === "Chegada Adiantada / Janela Sem Pedido") {
      return "#36414c";
    }
    if (counter === "Atrasado / DHL") {
      return "#FFC34A";
    }
    if (counter === "Exceção") {
      return "#3490fa";
    }
  }

  function capitalize(string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  function renderCounters() {
    let items = state.counters.map(item => {
      return (
        <>
          <p
            style={{
              margin: 0,
              padding: "6px 12px",
              borderRadius: "20px",
              marginLeft: 10,
              fontSize: 14
            }}
          >
            <LensIcon
              style={{ color: selectColor(item.counter), fontSize: 12 }}
            />{" "}
            {item.counter}
          </p>
        </>
      );
    });
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "space-between"
        }}
      >
        {items}
      </div>
    );
  }

  const filter = [
    { id: 0, name: "Todos" },
    { id: "green", name: "On Time" },
    { id: "red", name: "Atrasado" },
    { id: "orange", name: "Em Ocorrência" },
    { id: "gray", name: "Chegada Adiantada / Janela Sem Pedido" },
    { id: "blue", name: "Exceção" }
  ];
  const handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Main
      color="#f7f7f7"
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span
            style={{
              marginRight: 50,
              borderRight: "0.5px solid #fffffffc",
              paddingRight: 20,
              marginTop: 4
            }}
          >
            Mapa
          </span>
          {renderCounters()}
        </div>
      }
      filter={
        <CustomSelect
          id="component"
          label="Filtro"
          value={state.filter}
          name="filter"
          data={filter}
          onChange={event => handleChange(event)}
          fullWidth
        />
      }
    >
      <Flex>
        <MapContainer>
          <ReactBingmaps
            bingmapKey="ApORxoWOhCmB0PpjdQYHyNaz4CHBDtirxfiMfP-WYZIRpwMJDcxJo_kPUzf7NAp3"
            center={[-7.61640045411284, -34.95733737945556]}
            zoom={15}
            navigationBarMode={"square"}
            mapTypeId={"aerial" /*"road"*/}
            pushPins={state.pushPins}
          ></ReactBingmaps>
        </MapContainer>
        <SidebarContainer
          loading={state.loading}
          setColor={selectColor}
          setOpensidebar={setOpensidebar}
          opensidebar={opensidebar}
          item_sidebar={item_sidebar}
          occurrences={state.occurrences}
        ></SidebarContainer>
      </Flex>
    </Main>
  );
}

export default MapaOperacional;
